import * as tslib_1 from "tslib";
import { of as observableOf } from 'rxjs';
import { ProfitBarAnimationChartData } from '../data/profit-bar-animation-chart';
var ProfitBarAnimationChartService = /** @class */ (function (_super) {
    tslib_1.__extends(ProfitBarAnimationChartService, _super);
    function ProfitBarAnimationChartService() {
        var _this = _super.call(this) || this;
        _this.data = {
            firstLine: _this.getDataForFirstLine(),
            secondLine: _this.getDataForSecondLine(),
        };
        return _this;
    }
    ProfitBarAnimationChartService.prototype.getDataForFirstLine = function () {
        return this.createEmptyArray(100)
            .map(function (_, index) {
            var oneFifth = index / 5;
            return (Math.sin(oneFifth) * (oneFifth - 10) + index / 6) * 5;
        });
    };
    ProfitBarAnimationChartService.prototype.getDataForSecondLine = function () {
        return this.createEmptyArray(100)
            .map(function (_, index) {
            var oneFifth = index / 5;
            return (Math.cos(oneFifth) * (oneFifth - 10) + index / 6) * 5;
        });
    };
    ProfitBarAnimationChartService.prototype.createEmptyArray = function (nPoints) {
        return Array.from(Array(nPoints));
    };
    ProfitBarAnimationChartService.prototype.getChartData = function () {
        return observableOf(this.data);
    };
    return ProfitBarAnimationChartService;
}(ProfitBarAnimationChartData));
export { ProfitBarAnimationChartService };
