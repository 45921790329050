import * as tslib_1 from "tslib";
import { of as observableOf } from 'rxjs';
import { SolarData } from '../data/solar';
var SolarService = /** @class */ (function (_super) {
    tslib_1.__extends(SolarService, _super);
    function SolarService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.value = 42;
        return _this;
    }
    SolarService.prototype.getSolarData = function () {
        return observableOf(this.value);
    };
    return SolarService;
}(SolarData));
export { SolarService };
