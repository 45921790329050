import { Component, OnInit } from '@angular/core';
import { JarwisService } from '../../../_services/jarwis.service';
import {ToasterModule, ToasterService, ToasterConfig} from 'angular2-toaster';

@Component({
  selector: 'ngx-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.scss'],
})
export class RequestResetComponent implements OnInit {

  public form = {
    email: null
  }

  public config1 : ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right'
  });

  constructor(
    private Jarvis: JarwisService,
    private toasterService: ToasterService,
  ) {
    this.toasterService = toasterService;
   }

  ngOnInit() {
  }

  onSubmit(){
    this.Jarvis.sendPasswordResetLink(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error),
    );
  }

  handleResponse(res) {
    this.toasterService.pop('success', 'Email enviado', 'Acesse sua caixa de mensagens')
  }

  handleError(error){
    this.toasterService.pop('error', "Erro", 'Não foi possível identificar seu email. Tente novamente!')
  }

}
