import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

@Injectable()
export class ApiService {

  constructor(public http: HttpClient) {}

  post(url?: string, conteudo?: any): Observable<any> {
    return this.http.post<any>(environment.server_url + url, conteudo);
  }

  put(url?: string, conteudo?: any): Observable<any> {
    return this.http.put<any>(environment.server_url + url, conteudo);
  }

  delete(url?: string, conteudo?: any): Observable<any> {
    return this.http.delete<any>(environment.server_url + url, conteudo);
  }

  get(url?: string, conteudo?: any): Observable<any> {
    return this.http.get<any>(environment.server_url + url, conteudo);
  }

  show(url): Observable<any>{
    return this.http.get<any>(environment.server_url + url);
  }

}
