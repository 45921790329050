import * as tslib_1 from "tslib";
import { of as observableOf } from 'rxjs';
import { TemperatureHumidityData } from '../data/temperature-humidity';
var TemperatureHumidityService = /** @class */ (function (_super) {
    tslib_1.__extends(TemperatureHumidityService, _super);
    function TemperatureHumidityService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.temperatureDate = {
            value: 24,
            min: 12,
            max: 30,
        };
        _this.humidityDate = {
            value: 87,
            min: 0,
            max: 100,
        };
        return _this;
    }
    TemperatureHumidityService.prototype.getTemperatureData = function () {
        return observableOf(this.temperatureDate);
    };
    TemperatureHumidityService.prototype.getHumidityData = function () {
        return observableOf(this.humidityDate);
    };
    return TemperatureHumidityService;
}(TemperatureHumidityData));
export { TemperatureHumidityService };
