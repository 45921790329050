import { OnDestroy } from '@angular/core';
import { coerceCssPixelValue } from '@angular/cdk/coercion';
import { NbLayoutComponent, NbLayoutDimensions, NbLayoutRulerService, NbLayoutScrollService, NbViewportRulerAdapter, } from '@nebular/theme';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { fromEvent as observableFromEvent, merge, Subject } from 'rxjs';
var WindowModeBlockScrollService = /** @class */ (function () {
    function WindowModeBlockScrollService(scrollService, viewportRuler, layout, window) {
        this.scrollService = scrollService;
        this.viewportRuler = viewportRuler;
        this.layout = layout;
        this.window = window;
        this.destroy$ = new Subject();
        this.blockEnabled = false;
        this.unblock$ = new Subject();
    }
    WindowModeBlockScrollService.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
        this.unblock$.complete();
    };
    WindowModeBlockScrollService.prototype.register = function (layout) {
        var _this = this;
        this.container = layout.scrollableContainerRef.nativeElement;
        this.content = this.container.children[0];
        this.scrollService.onScrollableChange()
            .pipe(filter(function () { return layout.withScrollValue; }), map(function (scrollable) { return !scrollable; }), takeUntil(this.destroy$))
            .subscribe(function (shouldBlock) {
            if (shouldBlock) {
                _this.blockScroll();
            }
            else {
                _this.unblockScroll();
            }
        });
    };
    WindowModeBlockScrollService.prototype.blockScroll = function () {
        var _this = this;
        if (!this.canBeBlocked()) {
            return;
        }
        this.previousScrollPosition = this.viewportRuler.getViewportScrollPosition();
        this.backupStyles();
        this.container.style.overflowY = 'scroll';
        this.content.style.overflow = 'hidden';
        this.content.style.position = 'fixed';
        this.updateContentSizeAndPosition();
        observableFromEvent(this.window, 'resize')
            .pipe(takeUntil(merge(this.destroy$, this.unblock$).pipe(take(1))))
            .subscribe(function () { return _this.updateContentSizeAndPosition(); });
        this.blockEnabled = true;
    };
    WindowModeBlockScrollService.prototype.unblockScroll = function () {
        if (this.blockEnabled) {
            this.restoreStyles();
            this.scrollService.scrollTo(this.previousScrollPosition.left, this.previousScrollPosition.top);
            this.unblock$.next();
            this.blockEnabled = false;
        }
    };
    WindowModeBlockScrollService.prototype.canBeBlocked = function () {
        if (this.blockEnabled) {
            return false;
        }
        var containerHeight = this.viewportRuler.getViewportSize().height;
        return this.content.scrollHeight > containerHeight;
    };
    WindowModeBlockScrollService.prototype.updateContentSizeAndPosition = function () {
        var _this = this;
        var _a = this.container.getBoundingClientRect(), top = _a.top, left = _a.left;
        this.content.style.left = coerceCssPixelValue(-this.previousScrollPosition.left + left);
        this.content.style.top = coerceCssPixelValue(-this.previousScrollPosition.top + top);
        this.layout.getDimensions()
            .pipe(map(function (_a) {
            var clientWidth = _a.clientWidth;
            return coerceCssPixelValue(clientWidth);
        }), take(1))
            .subscribe(function (clientWidth) { return _this.content.style.width = clientWidth; });
    };
    WindowModeBlockScrollService.prototype.backupStyles = function () {
        this.previousContainerStyles = { overflowY: this.container.style.overflowY };
        this.previousContentStyles = {
            overflow: this.content.style.overflow,
            position: this.content.style.position,
            left: this.content.style.left,
            top: this.content.style.top,
            width: this.content.style.width,
        };
    };
    WindowModeBlockScrollService.prototype.restoreStyles = function () {
        this.container.style.overflowY = this.previousContainerStyles.overflowY;
        this.content.style.overflow = this.previousContentStyles.overflow;
        this.content.style.position = this.previousContentStyles.position;
        this.content.style.left = this.previousContentStyles.left;
        this.content.style.top = this.previousContentStyles.top;
        this.content.style.width = this.previousContentStyles.width;
    };
    return WindowModeBlockScrollService;
}());
export { WindowModeBlockScrollService };
