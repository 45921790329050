import * as tslib_1 from "tslib";
import { of as observableOf } from 'rxjs';
import { OrdersChartData } from '../data/orders-chart';
import { OrdersProfitChartData } from '../data/orders-profit-chart';
import { ProfitChartData } from '../data/profit-chart';
var OrdersProfitChartService = /** @class */ (function (_super) {
    tslib_1.__extends(OrdersProfitChartService, _super);
    function OrdersProfitChartService(ordersChartService, profitChartService) {
        var _this = _super.call(this) || this;
        _this.ordersChartService = ordersChartService;
        _this.profitChartService = profitChartService;
        _this.summary = [
            {
                title: 'Marketplace',
                value: 3654,
            },
            {
                title: 'Last Month',
                value: 946,
            },
            {
                title: 'Last Week',
                value: 654,
            },
            {
                title: 'Today',
                value: 230,
            },
        ];
        return _this;
    }
    OrdersProfitChartService.prototype.getOrderProfitChartSummary = function () {
        return observableOf(this.summary);
    };
    OrdersProfitChartService.prototype.getOrdersChartData = function (period) {
        return observableOf(this.ordersChartService.getOrdersChartData(period));
    };
    OrdersProfitChartService.prototype.getProfitChartData = function (period) {
        return observableOf(this.profitChartService.getProfitChartData(period));
    };
    return OrdersProfitChartService;
}(OrdersProfitChartData));
export { OrdersProfitChartService };
