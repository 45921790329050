import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var JarwisService = /** @class */ (function () {
    function JarwisService(http) {
        this.http = http;
        this.baseUrl = environment.server_url + 'api';
    }
    JarwisService.prototype.signup = function (data) {
        return this.http.post(this.baseUrl + "/signup", data);
    };
    JarwisService.prototype.login = function (data) {
        return this.http.post(this.baseUrl + "/login", data);
    };
    JarwisService.prototype.sendPasswordResetLink = function (data) {
        return this.http.post(this.baseUrl + "/sendPasswordResetLink", data);
    };
    JarwisService.prototype.changePassword = function (data) {
        return this.http.post(this.baseUrl + "/resetPassword", data);
    };
    JarwisService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JarwisService_Factory() { return new JarwisService(i0.ɵɵinject(i1.HttpClient)); }, token: JarwisService, providedIn: "root" });
    return JarwisService;
}());
export { JarwisService };
