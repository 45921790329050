import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate  {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService  
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ){
    const currentUser = this.authenticationService.currentUserValue;
    if(currentUser){
      if(route.data.roles && route.data.roles.indexOf(currentUser.role) === -1){
        this.router.navigate(['page/dashboard']);
        return false;
      }
      return true;
    }

    this.router.navigate(['/'], {queryParams: { returnUrl: state.url}});
    return false
  }
}
