import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-delete-box',
  templateUrl: './delete-box.component.html',
  styleUrls: ['./delete-box.component.scss']
})
export class DeleteBoxComponent implements OnInit {

  action: string;
  id: any;

  constructor(
    public dialogRef: NbDialogRef<DeleteBoxComponent>,
  ) { }

  ngOnInit() {
  }

  doAction(){
    this.dialogRef.close(this.id)
  }

  cancel(){
    this.dialogRef.close();
  }

}
