import * as tslib_1 from "tslib";
import { of as observableOf } from 'rxjs';
import { CountryOrderData } from '../data/country-order';
var CountryOrderService = /** @class */ (function (_super) {
    tslib_1.__extends(CountryOrderService, _super);
    function CountryOrderService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.countriesCategories = [
            'Sofas',
            'Furniture',
            'Lighting',
            'Tables',
            'Textiles',
        ];
        _this.countriesCategoriesLength = _this.countriesCategories.length;
        return _this;
    }
    CountryOrderService.prototype.generateRandomData = function (nPoints) {
        return Array.from(Array(nPoints)).map(function () {
            return Math.round(Math.random() * 20);
        });
    };
    CountryOrderService.prototype.getCountriesCategories = function () {
        return observableOf(this.countriesCategories);
    };
    CountryOrderService.prototype.getCountriesCategoriesData = function (country) {
        return observableOf(this.generateRandomData(this.countriesCategoriesLength));
    };
    return CountryOrderService;
}(CountryOrderData));
export { CountryOrderService };
