import * as tslib_1 from "tslib";
import { of as observableOf } from 'rxjs';
import { StatsBarData } from '../data/stats-bar';
var StatsBarService = /** @class */ (function (_super) {
    tslib_1.__extends(StatsBarService, _super);
    function StatsBarService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.statsBarData = [
            300, 520, 435, 530,
            730, 620, 660, 860,
        ];
        return _this;
    }
    StatsBarService.prototype.getStatsBarData = function () {
        return observableOf(this.statsBarData);
    };
    return StatsBarService;
}(StatsBarData));
export { StatsBarService };
