import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JarwisService } from '../../../_services/jarwis.service';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'ngx-response-reset',
  templateUrl: './response-reset.component.html',
  styleUrls: ['./response-reset.component.scss']
})
export class ResponseResetComponent implements OnInit {

  public error = [];
  public form = {
    email: null,
    password: null,
    password_confirmation: null,
    resetToken: null,
  }

  constructor(
    private route: ActivatedRoute,
    private Jarwis: JarwisService,
    private router: Router,
    private toasterService: ToasterService
  ) {
    route.queryParams.subscribe(params => {
      this.form.resetToken = params['token']
    });
   }

   onSubmit(){
     this.Jarwis.changePassword(this.form).subscribe(
       data => this.handleResponse(data),
       error => this.handleError(error),
     )
   }

   handleResponse(data){
     this.router.navigate(['/login']).then(() => {
        this.toasterService.pop('success', 'Pronto!', 'Alteração de senha realizada com sucesso!');
     });     
   }

   handleError(error){
     this.toasterService.pop('error', 'Falha!', 'Ocorreu um erro, tente novamente!')
   }

  ngOnInit() {
  }

}
