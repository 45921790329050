import * as tslib_1 from "tslib";
import { of as observableOf } from 'rxjs';
import { PeriodsService } from './periods.service';
import { TrafficBarData } from '../data/traffic-bar';
var TrafficBarService = /** @class */ (function (_super) {
    tslib_1.__extends(TrafficBarService, _super);
    function TrafficBarService(period) {
        var _this = _super.call(this) || this;
        _this.period = period;
        _this.data = {};
        _this.data = {
            week: _this.getDataForWeekPeriod(),
            month: _this.getDataForMonthPeriod(),
            year: _this.getDataForYearPeriod(),
        };
        return _this;
    }
    TrafficBarService.prototype.getDataForWeekPeriod = function () {
        return {
            data: [10, 15, 19, 7, 20, 13, 15],
            labels: this.period.getWeeks(),
            formatter: '{c0} MB',
        };
    };
    TrafficBarService.prototype.getDataForMonthPeriod = function () {
        return {
            data: [0.5, 0.3, 0.8, 0.2, 0.3, 0.7, 0.8, 1, 0.7, 0.8, 0.6, 0.7],
            labels: this.period.getMonths(),
            formatter: '{c0} GB',
        };
    };
    TrafficBarService.prototype.getDataForYearPeriod = function () {
        return {
            data: [10, 15, 19, 7, 20, 13, 15, 19, 11],
            labels: this.period.getYears(),
            formatter: '{c0} GB',
        };
    };
    TrafficBarService.prototype.getTrafficBarData = function (period) {
        return observableOf(this.data[period]);
    };
    return TrafficBarService;
}(TrafficBarData));
export { TrafficBarService };
