<img class="rounded mx-auto d-block" src="../../../../admin-angular/assets/images/logo-admin.jpg">
<h1 id="title" class="title">Recuperação de Acesso</h1>
<p class="sub-title">Informe seu endereço de email para receber um link de recuperação de acesso.</p>

<form (ngSubmit)="onSubmit()" #RequestResetForm=ngForm >

  <div class="form-control-group">
    <label class="label" for="email">Informe seu email:</label>
    <input nbInput
           [(ngModel)]="form.email"
           id="email"
           name="email"
           pattern=".+@.+\..+"
           placeholder="E-mail"
           autofocus
           fullWidth
           fieldSize="large"
           >
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="!RequestResetForm.valid"
          type="submit">
    Solicitar nova senha
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Voltar para login</a></p>
</section>
<toaster-container></toaster-container>

