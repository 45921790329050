import { OnInit } from '@angular/core';
import { AuthenticationService } from '../../../_services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, route, router, Auth, toast) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.Auth = Auth;
        this.toast = toast;
        this.loading = false;
        this.submitted = false;
        this.error = '';
        if (this.Auth.currentUserValue) {
            this.router.navigate(['page/dashboard']);
        }
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        get: function () { return this.loginForm.controls; },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.Auth.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(function (data) {
            _this.router.navigate([_this.returnUrl]);
            _this.toast.success;
        }, function (error) {
            _this.error = error;
            _this.toast.danger;
            _this.loading = false;
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
