import * as tslib_1 from "tslib";
import { of as observableOf } from 'rxjs';
import { TrafficChartData } from '../data/traffic-chart';
var TrafficChartService = /** @class */ (function (_super) {
    tslib_1.__extends(TrafficChartService, _super);
    function TrafficChartService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.data = [
            300, 520, 435, 530,
            730, 620, 660, 860,
        ];
        return _this;
    }
    TrafficChartService.prototype.getTrafficChartData = function () {
        return observableOf(this.data);
    };
    return TrafficChartService;
}(TrafficChartData));
export { TrafficChartService };
