import { ExtraOptions, Routes } from '@angular/router';
import { NbAuthComponent, } from '@nebular/auth';
import { LoginComponent } from './_components/auth/login/login.component';
import { RequestResetComponent } from './_components/auth/request-reset/request-reset.component';
import { ResponseResetComponent } from './_components/auth/response-reset/response-reset.component';
var ɵ0 = function () { return import("./pages/pages.module.ngfactory")
    .then(function (m) { return m.PagesModuleNgFactory; }); };
var routes = [
    {
        path: 'page',
        loadChildren: ɵ0,
    },
    {
        path: '',
        component: NbAuthComponent,
        children: [
            {
                path: '',
                component: LoginComponent,
            },
            {
                path: 'request-reset',
                component: RequestResetComponent,
            },
            {
                path: 'response-reset',
                component: ResponseResetComponent,
            }
        ],
    },
    { path: '', redirectTo: 'pages', pathMatch: 'full' },
    { path: '**', redirectTo: 'pages' },
];
var config = {
    useHash: false,
};
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
