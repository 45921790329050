<h1 id="title" class="title">Alterar senha</h1>
<p class="sub-title">Por favor informe uma nova senha</p>

<form (ngSubmit)="onSubmit()" #resetPassForm="ngForm" aria-labelledby="title">

        <div class="form-control-group">
                <label class="label" for="email">Seu Email:</label>
                <input nbInput
                       [(ngModel)]="form.email"
                       type="email"
                       id="email"
                       name="email"
                       class="first"
                       placeholder="Informe o email cadastrado"
                       autofocus
                       fullWidth
                       fieldSize="large"
                >
        </div>
  
    <div class="form-control-group">
    <label class="label" for="input-password">Nova Senha:</label>
    <input nbInput
           [(ngModel)]="form.password"
           type="password"
           id="password"
           name="password"
           class="first"
           placeholder="Nova Senha"
           autofocus
           fullWidth
           fieldSize="large"
    >
  </div>

  <div class="form-group">
    <label class="label" for="input-re-password">Confirmar Senha:</label>
    <input nbInput
           [(ngModel)]="form.password_confirmation"
           id="password_confirmation"
           name="password_confirmation"
           type="password"
           class="last"
           placeholder="Confirmar Senha"
           fullWidth
           fieldSize="large"
    >
  </div>

  <button nbButton
          status="primary"
          fullWidth
          size="large">
    Alterar senha
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Voltar para Login</a></p>
</section>
<toaster-container></toaster-container>
