import * as tslib_1 from "tslib";
import { of as observableOf } from 'rxjs';
import { SecurityCamerasData } from '../data/security-cameras';
var SecurityCamerasService = /** @class */ (function (_super) {
    tslib_1.__extends(SecurityCamerasService, _super);
    function SecurityCamerasService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cameras = [
            {
                title: 'Camera #1',
                source: 'assets/images/camera1.jpg',
            },
            {
                title: 'Camera #2',
                source: 'assets/images/camera2.jpg',
            },
            {
                title: 'Camera #3',
                source: 'assets/images/camera3.jpg',
            },
            {
                title: 'Camera #4',
                source: 'assets/images/camera4.jpg',
            },
        ];
        return _this;
    }
    SecurityCamerasService.prototype.getCamerasData = function () {
        return observableOf(this.cameras);
    };
    return SecurityCamerasService;
}(SecurityCamerasData));
export { SecurityCamerasService };
