<img class="rounded mx-auto d-block" src="../assets/images/logo-admin.jpg">
<p class="sub-title">Acesso restrito</p>

<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="email">Email:
    </label>
    <input nbInput
      formControlName="email"
      type="email"
      fullWidth
      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
      name="email"
      id="email"
      placeholder="Informe seu email"
      fieldSize="large"
      autofocus>
      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
        <div *ngIf="f.email.errors.required">O email é obrigatório!</div>
      </div>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="password">Senha:</label>
      <a class="forgot-password caption-2" [routerLink]="['/request-reset']">Esqueceu sua senha?</a>
    </span>
    <input nbInput
      fullWidth
      formControlName="password"
      name="password"
      type="password"
      id="password"
      placeholder="Informe sua senha"
      fieldSize="large"
      [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
      >
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors.required">A senha é obrigatória!</div>
      </div>
    <ng-container>
    </ng-container>
  </div>

  <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe">Manter conectado</nb-checkbox>
  </div>
  <button nbButton
          fullWidth
          status="primary"
          size="large"
          type="submit">
    Acessar
  </button>
</form>
<div *ngIf="error" class="alert alert-danger">{{error}}</div>


