import * as tslib_1 from "tslib";
import { of as observableOf } from 'rxjs';
import { StatsProgressBarData } from '../data/stats-progress-bar';
var StatsProgressBarService = /** @class */ (function (_super) {
    tslib_1.__extends(StatsProgressBarService, _super);
    function StatsProgressBarService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.progressInfoData = [
            {
                title: 'Today’s Profit',
                value: 572900,
                activeProgress: 70,
                description: 'Better than last week (70%)',
            },
            {
                title: 'New Orders',
                value: 6378,
                activeProgress: 30,
                description: 'Better than last week (30%)',
            },
            {
                title: 'New Comments',
                value: 200,
                activeProgress: 55,
                description: 'Better than last week (55%)',
            },
        ];
        return _this;
    }
    StatsProgressBarService.prototype.getProgressInfoData = function () {
        return observableOf(this.progressInfoData);
    };
    return StatsProgressBarService;
}(StatsProgressBarData));
export { StatsProgressBarService };
